import { CONFIG, POST_PRIVACY, RESOURCES, toFixed, useUserStore } from '@worklage/shared';
import Compress from 'compress.js';
import { Storage } from './StorageService';

const compress = new Compress();

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const resizeImageFn = async (maxWidth: number = 300, file: any) => {
  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.7, // the quality of the image, max is 1,
    maxWidth: maxWidth, // the max width of the output image, defaults to 1920px
    maxHeight: 1920, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const dimens = {
    width: toFixed(img.endWidthInPx),
    height: toFixed(img.endHeightInPx)
  };
  return { dimens, file: Compress.convertBase64ToFile(img.data, img.ext) };
};

export const getUserImage = (image: any) => {
  return !!image && image !== 'undefined' ? CONFIG.FILES_URL + 'users/' + image : RESOURCES.USER;
};

export const getProjectImage = (image: any) => {
  return !!image && image !== 'undefined' ? CONFIG.FILES_URL + 'projects/' + image : RESOURCES.USER;
};

export const isMyUuid = (userUuid: string) => {
  const myUuid = Storage.get('UUID');
  return myUuid === userUuid;
};

export const setUserFromStorage = async () => {
  const UUID = Storage.get('UUID');
  const TOKEN = Storage.get('TOKEN');
  const UROLE = Storage.get('UROLE');
  const UBUUID = Storage.get('UBUUID');
  const UNAME = Storage.get('UNAME');
  const UIMAGE = Storage.get('UIMAGE');
  return !!UUID && useUserStore.setState({ UUID, UBUUID, UROLE, UNAME, UIMAGE, TOKEN });
};

export const is_touch_enabled = () => {
  // @ts-ignore
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const viewFile = (url: string) => {
  window.open(url, '_blank');
};

export const privacyOptions = [POST_PRIVACY.PUBLIC, POST_PRIVACY.PRIVATE];
