import React from 'react';

interface Props {
  text: string;
}

const NoData = (props: Props) => {
  return <div className="text-center p-3 text">{props.text}</div>;
};

export default NoData;
