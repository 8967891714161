import React, { useRef, useState } from 'react';
import { CONFIG, extendFormData, getFileName, useAddGroupMutation, useUpdateGroupMutation } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import * as _ from 'lodash';
import { Image } from 'lucide-react';
import { NButton, NButtonGroup, useToast } from 'nayan';
import { getUserImage, privacyOptions, resizeImageFn } from '../../services/WebUtils';
import Required from '../helpers/elements/Required';

interface Props {
  group?: any;
  closeModal: any;
}

const GroupNew: React.FC<Props> = (props: Props) => {
  const { group } = props;
  const toast = useToast();
  const [privacy, setPrivacy] = useState<any>(group?.privacy ? privacyOptions.find(i => i === group.privacy) : privacyOptions[0]);
  const [myFile, setFile] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const inputImageRef = useRef<any>(null);
  const addGroupMutation = useAddGroupMutation();
  const updateGroupMutation = useUpdateGroupMutation();
  const initialValues = {
    name: group?.name || '',
    about: group?.about || ''
  };

  const getTempFile = () => {
    return URL.createObjectURL(myFile);
  };

  return (
    <div className="profile-edit p-3">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          setIsLoading(true);
          if (myFile) {
            const resizeImg = await resizeImageFn(320, myFile);
            const groupData = _.assign({}, group, values, { image: getFileName(myFile.name), privacy: privacy.id });
            const formData = new FormData();
            formData.append('myFile', resizeImg.file);
            extendFormData(formData, groupData);
            if (!group?.uuid) {
              addGroupMutation
                .mutateAsync({ group: groupData, formData })
                .then((res: any) => props.closeModal())
                .catch(() => toast('Something went wrong, try again later.'))
                .finally(() => setIsLoading(false));
            } else {
              updateGroupMutation
                .mutateAsync({ group: groupData, formData })
                .then(() => props.closeModal())
                .catch(() => toast('Something went wrong, try again later.'))
                .finally(() => setIsLoading(false));
            }
          } else if (!myFile && values.name) {
            const groupData = _.assign({}, group, values, { privacy: privacy.id });
            if (!group?.uuid) {
              addGroupMutation
                .mutateAsync({ group: groupData })
                .then(() => props.closeModal())
                .finally(() => setIsLoading(false));
            } else {
              updateGroupMutation
                .mutateAsync({ group: groupData })
                .then(() => props.closeModal())
                .finally(() => setIsLoading(false));
            }
          }
        }}>
        <Form>
          <div className="profile-edit-image">
            <img className="image-container w-[80px] h-[80px]" src={!!myFile ? getTempFile() : getUserImage(group?.image)} alt="" />
            <div>
              <div>
                <input type="file" className="myFile hidden" accept="image/*" ref={inputImageRef} onChange={(e: any) => setFile(e.target.files[0])} />
                <span
                  className="add-post-btn cursor-pointer flex items-center"
                  onClick={() => inputImageRef.current && inputImageRef.current.click()}>
                  <Image size="1.5em" className="text-primary" />
                  <span className="pl-2 text-primary"> Change group icon</span>
                </span>
              </div>
              <div className="text-muted mt-2">Select new image to change your group icon.</div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="name" className="text">
              Group Name
              <Required />
            </label>
            <Field className="form-control" type="text" name="name" />
          </div>

          <div className="mb-3">
            <label htmlFor="name" className="text">
              Group Description
              <Required />
            </label>
            <Field as="textarea" className="form-control" name="about" rows="3" placeholder="Say something about your group." />
          </div>

          <div className="mb-3 flex justify-between items-center">
            <div className="text">
              Privacy
              <Required />
            </div>
            <div className="text">
              <NButtonGroup items={privacyOptions} selected={privacy} onChange={setPrivacy} />
            </div>
          </div>

          <div className="d-grid gap-3">
            <NButton type="submit" className="w-full" isLoading={isLoading}>
              SUBMIT
            </NButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default GroupNew;
