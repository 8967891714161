import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { CLIENT_TYPES, restClient } from '@worklage/shared';
import { NLoading } from 'nayan';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Projects from './applications/projects/Projects';
import About from './components/about/About';
import Auth from './components/auth/Auth';
import Reset from './components/auth/Reset';
import Verify from './components/auth/Verify';
import Community from './components/community/Community';
import GroupDetails from './components/groups/GroupDetails';
import ProtectedRoutes from './components/helpers/protected-route/ProtectedRoute';
import Seo from './components/helpers/Seo';
import AppWrapper from './components/helpers/wrapper/AppWrapper';
import Wrapper from './components/helpers/wrapper/Wrapper';
import WrapperThreeColumn from './components/helpers/wrapper/WrapperThreeColumn';
import WrapperTwoColumn, { SIDEBAR_BLOCK_TYPE } from './components/helpers/wrapper/WrapperTwoColumn';
import Home from './components/home/Home';

const AssetsLazy = React.lazy(() => import('./applications/assets/Assets'));
const AssetsDetailsLazy = React.lazy(() => import('./applications/assets/AssetsDetails'));
const BusinessLazy = React.lazy(() => import('./applications/business/Business'));
const BusinessDetailsLazy = React.lazy(() => import('./applications/business/BusinessDetails'));
const DocumentsLazy = React.lazy(() => import('./applications/documents/Documents'));
const FormsLazy = React.lazy(() => import('./applications/forms/Forms'));
const FormsCreateLazy = React.lazy(() => import('./applications/forms/FormsCreate'));
const FormsDetailsLazy = React.lazy(() => import('./applications/forms/FormsDetails'));
const FormsReplysLazy = React.lazy(() => import('./applications/forms/FormsReplys'));
const PagesLazy = React.lazy(() => import('./applications/pages/Pages'));
const PagesNewLazy = React.lazy(() => import('./applications/pages/PagesNew'));
const PagesDetailsLazy = React.lazy(() => import('./applications/pages/PagesDetails'));
const IntegrationsLazy = React.lazy(() => import('./applications/integrations/Integrations'));
const LeavesLazy = React.lazy(() => import('./applications/leaves/Leaves'));
const MyLeavesLazy = React.lazy(() => import('./applications/leaves/my-leaves/MyLeaves'));
const LeaveReportsLazy = React.lazy(() => import('./applications/leaves/reports/Reports'));
const ProjectDetailsLazy = React.lazy(() => import('./applications/projects/ProjectDetails'));
const ClientsLazy = React.lazy(() => import('./applications/sales/clients/Clients'));
const QuotationsDetailsLazy = React.lazy(() => import('./applications/sales/quotations/QuotationsDetails'));
const QuotationsLazy = React.lazy(() => import('./applications/sales/quotations/Quotations'));
const GroupsLazy = React.lazy(() => import('./components/groups/Groups'));
const MyGroupsLazy = React.lazy(() => import('./components/groups/MyGroups'));
const NotificationsLazy = React.lazy(() => import('./components/notifications/Notifications'));
const PostLazy = React.lazy(() => import('./components/posts/Post'));
const PostsLazy = React.lazy(() => import('./components/posts/Posts'));
const ProfileLazy = React.lazy(() => import('./components/profile/Profile'));
const ChatsLazy = React.lazy(() => import('./components/chats/Chats'));
const SettingsLazy = React.lazy(() => import('./components/settings/Settings'));
const CareersLazy = React.lazy(() => import('./components/careers/Careers'));
const TermsOfUseLazy = React.lazy(() => import('./components/policies/TermsOfUse'));
const PrivacyPoliciesLazy = React.lazy(() => import('./components/policies/PrivacyPolicies'));

const App = () => {
  return (
    <QueryClientProvider client={restClient as any}>
      <AppWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/signup" element={<Auth />} />
            <Route path="/forgot-password" element={<Auth />} />
            <Route path="/verify/:userUuid/:resetUuid" element={<Verify />} />
            <Route path="/reset-password/:userUuid/:resetUuid" element={<Reset />} />
            <Route
              path="/careers"
              element={
                <Wrapper isContainer={false}>
                  <React.Suspense fallback={<NLoading />}>
                    <CareersLazy />
                  </React.Suspense>
                </Wrapper>
              }
            />
            <Route
              path="/terms-of-use"
              element={
                <Wrapper isContainer={false}>
                  <React.Suspense fallback={<NLoading />}>
                    <TermsOfUseLazy />
                  </React.Suspense>
                </Wrapper>
              }
            />
            <Route
              path="/privacy-policies"
              element={
                <Wrapper isContainer={false}>
                  <React.Suspense fallback={<NLoading />}>
                    <PrivacyPoliciesLazy />
                  </React.Suspense>
                </Wrapper>
              }
            />
            <Route element={<ProtectedRoutes />}>
              <Route
                path="/settings/:tab?"
                element={
                  <Wrapper>
                    <React.Suspense fallback={<NLoading />}>
                      <SettingsLazy />
                    </React.Suspense>
                  </Wrapper>
                }
              />
              <Route
                path="/messages"
                element={
                  <Wrapper>
                    <Seo title="Messages | Worklage" />
                    <React.Suspense fallback={<NLoading />}>
                      <ChatsLazy />
                    </React.Suspense>
                  </Wrapper>
                }
              />
              <Route
                path="/notifications"
                element={
                  <WrapperThreeColumn>
                    <Seo title="Notifications | Worklage" />
                    <React.Suspense fallback={<NLoading />}>
                      <NotificationsLazy />
                    </React.Suspense>
                  </WrapperThreeColumn>
                }
              />
              <Route path="/timeline">
                <Route
                  index
                  element={
                    <WrapperThreeColumn>
                      <Seo title="Timeline | Worklage" />
                      <React.Suspense fallback={<NLoading />}>
                        <PostsLazy />
                      </React.Suspense>
                    </WrapperThreeColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <Wrapper>
                      <Seo title="Post | Worklage" />
                      <React.Suspense fallback={<NLoading />}>
                        <PostLazy />
                      </React.Suspense>
                    </Wrapper>
                  }
                />
              </Route>
              <Route path="/profile">
                <Route
                  index
                  element={
                    <WrapperTwoColumn>
                      <React.Suspense fallback={<NLoading />}>
                        <ProfileLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn>
                      <React.Suspense fallback={<NLoading />}>
                        <ProfileLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/community">
                <Route index element={<Community />} />
                <Route path=":tab" element={<Community />} />
              </Route>
              <Route path="/groups">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <GroupsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route path=":uuid/:tab?" element={<GroupDetails />} />
                <Route
                  path="mine"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <MyGroupsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/businesses">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <BusinessLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <BusinessDetailsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/integrations">
                <Route
                  index
                  element={
                    <WrapperTwoColumn>
                      <React.Suspense fallback={<NLoading />}>
                        <IntegrationsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/projects">
                <Route index element={<Projects />} />
                <Route
                  path=":uuid/:tab?"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.PROJECT}>
                      <React.Suspense fallback={<NLoading />}>
                        <ProjectDetailsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/assets">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <AssetsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <AssetsDetailsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/pages">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <PagesLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <PagesDetailsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path="new/:uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <PagesNewLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/forms">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <FormsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <FormsDetailsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path="new/:uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <FormsCreateLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path="replys/:uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <FormsReplysLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/documents">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <DocumentsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/sales">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsLazy type={CLIENT_TYPES.CLIENT} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`quotations`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsLazy type={CLIENT_TYPES.CLIENT} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`clients`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <ClientsLazy type={CLIENT_TYPES.CLIENT} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`quotations/:uuid`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsDetailsLazy type={CLIENT_TYPES.CLIENT} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/purchases">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsLazy type={CLIENT_TYPES.VENDOR} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`orders`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsLazy type={CLIENT_TYPES.VENDOR} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`vendors`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <ClientsLazy type={CLIENT_TYPES.VENDOR} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path={`orders/:uuid`}
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <QuotationsDetailsLazy type={CLIENT_TYPES.VENDOR} />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
              <Route path="/leaves">
                <Route
                  index
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <LeavesLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path=":uuid"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <MyLeavesLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
                <Route
                  path="reports"
                  element={
                    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
                      <React.Suspense fallback={<NLoading />}>
                        <LeaveReportsLazy />
                      </React.Suspense>
                    </WrapperTwoColumn>
                  }
                />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </AppWrapper>
    </QueryClientProvider>
  );
};

export default App;
